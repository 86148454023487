import { PageId } from '../../constants/page-ids';

const translationsPages = {
  [PageId.Branding]: {
    label: 'Branding',
  },
  [PageId.Clients]: {
    label: 'Clients',
  },
  [PageId.ClientsDetails]: {
    label: 'Client Details',
  },
  [PageId.ClientsRoutineDetails]: {
    label: 'Routine Details',
  },
  [PageId.ClientsWorkoutDetails]: {
    label: 'Workout Details',
  },
  [PageId.Coaches]: {
    label: 'Coaches',
  },
  [PageId.Company]: {
    label: 'Company',
  },
  [PageId.Exercises]: {
    label: 'Exercises',
  },
  [PageId.Facilities]: {
    content: {},
    label: 'Facilities',
  },
  [PageId.ForgotPassword]: {
    label: 'Forgot Password',
  },
  [PageId.Home]: {
    label: 'Home',
  },
  [PageId.Library]: {
    label: 'Library',
  },
  [PageId.MyStuff]: {
    label: 'My Stuff',
  },
  [PageId.NewPasswordChallenge]: {
    label: 'New Password',
  },
  [PageId.Onboarding]: {
    label: 'Onboarding',
  },
  [PageId.Products]: {
    label: 'Products',
  },
  [PageId.ProductsCheckout]: {
    label: 'Checkout',
  },
  [PageId.ResetPassword]: {
    label: 'Reset Password',
  },
  [PageId.Settings]: {
    label: 'Settings',
  },
  [PageId.SettingsProfile]: {
    label: 'Profile Settings',
  },
  [PageId.SettingsSubscriptions]: {
    label: 'Subscriptions',
  },
  [PageId.SignIn]: {
    label: 'Sign In',
  },
  [PageId.SignUp]: {
    label: 'Sign Up',
  },
  [PageId.TemplateRoutines]: {
    label: 'Template Routines',
  },
  [PageId.TemplateWorkouts]: {
    label: 'Template Workouts',
  },
  [PageId.VerifyCode]: {
    label: 'Verify Code',
  },
  [PageId.WorkoutBuilder]: {
    label: 'Workout Builder',
  },
};

export default translationsPages;
