import {
  AuthSession,
  confirmSignUp as cognitoConfirmSignUp,
  signIn as cognitoSignIn,
  signUp as cognitoSignUp,
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  resendSignUpCode,
} from 'aws-amplify/auth';

import { queryClient } from '../../config/react-query';
import { User, UserType } from '../../features/user/types';
import {
  PaddleProductType,
  PaddleProductVariant,
  PaddleSubscriptionStatus,
} from '../../types/product.types';
import { clearMixpanelUser, setMixpanelUser } from '../analytics';
import { clearSentryUser, setSentryUser } from '../sentry';
import { StorageKeys, StorageService } from '../storage';
import {
  IConfirmSignUp,
  IResendVerificationCode,
  ISignIn,
  ISignUp,
} from './types';

const setUser = (user: null | User) => {
  if (user === null) {
    clearAuthState();
  } else {
    StorageService.save(StorageKeys.User, user);
    setSentryUser(user);
    setMixpanelUser(user);
  }
};

// eslint-disable-next-line complexity
const buildUser = (
  sessionUserAttributes: FetchUserAttributesOutput,
  session: AuthSession,
): null | User => {
  if (!sessionUserAttributes) {
    return null;
  }

  // const groups = session.tokens?.accessToken.payload?.['cognito:groups'] ?? [];
  const idToken = session.tokens?.idToken?.payload ?? {};

  const customerId = idToken.customerId;

  const subscriptionMaxUsers = idToken.subscriptionMaxUsers
    ? Number(idToken.subscriptionMaxUsers)
    : -1;

  const subscriptionPriceId = (idToken.subscriptionPriceId ?? null) as
    | null
    | string;

  const subscriptionStatus = idToken.subscriptionStatus
    ? (idToken.subscriptionStatus as PaddleSubscriptionStatus)
    : null;

  const subscriptionVariant = idToken.subscriptionVariant
    ? (idToken.subscriptionVariant as PaddleProductVariant)
    : null;

  const subscriptionType = idToken.subscriptionType
    ? (idToken.subscriptionType as PaddleProductType)
    : null;

  const subscriptionTier = idToken.subscriptionTier
    ? +idToken.subscriptionTier
    : -1;

  const userType = idToken?.userType as UserType;

  const user: User = {
    avatarUrl: null,
    customerId: (customerId ?? null) as null | string,
    email: sessionUserAttributes.email ?? '',
    firstName: sessionUserAttributes.given_name ?? '',
    id: sessionUserAttributes.sub ?? '',
    lastName: sessionUserAttributes.family_name ?? '',
    subscriptionMaxUsers,
    subscriptionPriceId,
    subscriptionStatus,
    subscriptionTier,
    subscriptionType,
    subscriptionVariant,
    type: userType || UserType.New,
  };

  return user;
};

/**
 * Get the current user from the Cognito session.
 * @returns A promise that resolves to the session user or rejects with an error.
 */
// eslint-disable-next-line complexity
export async function getCurrentCognitoUser() {
  const [sessionUser, session] = await Promise.all([
    fetchUserAttributes(),
    fetchAuthSession(),
  ]);

  const user = buildUser(sessionUser, session);

  if (user === null) {
    clearAuthState();
  }

  setUser(user);

  return user;
}

// /**
//  * Get the Cognito user session for the current user.
//  * @returns A promise that resolves to the Cognito user session or rejects with an error.
//  */
// export function getSession() {
//   return fetchAuthSession();
// }

/**
 * Sign up a user.
 * @param signUpData           - The sign-up data.
 * @param signUpData.email     - The user's email.
 * @param signUpData.firstName - The user's first name.
 * @param signUpData.lastName  - The user's last name.
 * @param signUpData.password  - The user's password.
 * @returns                    A promise that resolves to the sign-up result or rejects with an error.
 */
export function signUp({ email, firstName, lastName, password }: ISignUp) {
  return cognitoSignUp({
    options: {
      userAttributes: {
        family_name: lastName,
        given_name: firstName,
      },
    },
    password,
    username: email,
  });
}

/**
 * Confirm a user's sign-up.
 * @param confirmData                The confirmation data.
 * @param confirmData.code           The confirmation code.
 * @param confirmData.email          The user's email.
 * @param confirmData.clientMetadata Optional client metadata (e.g., coachId).
 * @returns                          A promise that resolves when the confirmation is successful or rejects with an error.
 */
export function confirmSignUp({ clientMetadata, code, email }: IConfirmSignUp) {
  return cognitoConfirmSignUp({
    confirmationCode: code,
    options: { clientMetadata },
    username: email,
  });
}

/**
 * Resend a verification code to a user.
 * @param resendData         - The resend data.
 * @param resendData.coachId - The ID of the coach (optional).
 * @param resendData.email   - The user's email.
 * @returns                  A promise that resolves to `true` when the code is successfully resent or rejects with an error.
 */
export function resendVerificationCode({
  coachId,
  email,
}: IResendVerificationCode) {
  const clientMetadata = coachId ? { coachId, email } : undefined;
  return resendSignUpCode({ options: { clientMetadata }, username: email });
}

/**
 * Sign in a user.
 * @param signInData                - The sign-in data.
 * @param signInData.email          - The user's email.
 * @param signInData.password       - The user's password.
 * @param signInData.clientMetadata - Optional client metadata.
 * @returns                         A promise that resolves to the user session or rejects with an error.
 */
export async function signIn({ clientMetadata, email, password }: ISignIn) {
  await cognitoSignIn({
    options: { clientMetadata },
    password,
    username: email,
  });
}

export function clearAuthState() {
  if (window.navigator.onLine === false) {
    return;
  }
  StorageService.remove(StorageKeys.User);
  queryClient.invalidateQueries();
  queryClient.removeQueries();
  clearSentryUser();
  clearMixpanelUser();
}

export async function refreshSession() {
  try {
    const authSession = await fetchAuthSession({ forceRefresh: true });
    const userAttributes = await fetchUserAttributes();

    const user = buildUser(userAttributes, authSession);

    setUser(user);
    return user;
  } catch (error) {
    clearAuthState();
    return null;
  }
}
