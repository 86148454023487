import {
  PaddleProductType,
  PaddleProductVariant,
  PaddleSubscriptionStatus,
} from '../../types/product.types';

interface PlanVariant {
  cta: string;
  description: string;
  items: string;
  name: string;
}

interface Plan {
  description: string;
  features: { description: string; title: string }[];
  heading: string;
  headingDesktop: string;
}

const plans: Record<
  PaddleProductType,
  Plan & Record<PaddleProductVariant, PlanVariant>
> = {
  business: {
    description: 'Suited for companies with multiple coaches and gyms',
    features: [
      {
        description: 'And yes, you as business owner... ???',
        title: 'Everything from coaches plan',
      },
      {
        description: 'Allow all your coaches to get???',
        title: 'Your Coaches',
      },
      {
        description:
          'Create exercises and templates that are the blueprint of your business',
        title: 'Library',
      },
      { description: 'Something more', title: 'Title' },
    ],
    heading: 'Business',
    headingDesktop: 'For Businesses',
    pro: {
      cta: 'Get Started',
      description: 'Some description',
      items: 'Item 1;Item 2;Item 3',
      name: 'Business Pro',
    },
    standard: {
      cta: 'Get Started',
      description: 'Some description',
      items: 'Item 1;Item 2;Item 3',
      name: 'Business Standard',
    },
    starter: {
      cta: 'Get Started',
      description: 'Some description',
      items: 'Item 1;Item 2;Item 3',
      name: 'Business Starter',
    },
  },
  coach: {
    description: 'Suited if you are an individual coach',
    features: [
      {
        description: 'Yes, you can use the product for your programs too!',
        title: 'Everything from personal plan, for you and your clients.',
      },
      {
        description: 'Give your clients the best coaching experience.',
        title: 'Your Clients',
      },
      {
        description: 'Create templates for whole programs.',
        title: 'Program Templates',
      },
      {
        description:
          'Many options how to coach your clients. Online, in-person or both.',
        title: 'Online or In-Person',
      },
      {
        description:
          'Many tools that help you to find exactly what your clients need to achieve the best results.',
        title: 'Tools to maximize client results',
      },
    ],
    heading: 'Coach',
    headingDesktop: 'For Coaches',
    pro: {
      cta: 'Get Started',
      description: '50 clients',
      items: 'Item 1;Item 2;Item 3',
      name: 'Coach Pro',
    },
    standard: {
      cta: 'Get Started',
      description: '20 clients',
      items: 'Item 1;Item 2;Item 3',
      name: 'Coach Standard',
    },
    starter: {
      cta: 'Get Started',
      description: '7 clients',
      items: 'Item 1;Item 2;Item 3',
      name: 'Coach Starter',
    },
  },
  personal: {
    description:
      'Good choice for individuals that take their training seriously.',
    features: [
      {
        description: 'Our State of the art program builder',
        title: 'Program Builder',
      },
      {
        description: 'Create your own exercises, use our or personalize ours.',
        title: 'Exercise Library',
      },
      {
        description: 'Create templates of workouts, to build workouts faster.',
        title: 'Template Workouts',
      },
      {
        description: 'Analyze how your program is affecting you.',
        title: 'Program analysis',
      },
      {
        description: 'Track your progress for everything!',
        title: 'Progress & Wellbeing tracking',
      },
    ],
    heading: 'Personal',
    headingDesktop: 'For Individuals',
    pro: {
      cta: 'Get Started',
      description: 'Some description',
      items: 'Item 1;Item 2;Item 3',
      name: 'Personal Pro',
    },
    standard: {
      cta: 'Get Started',
      description: 'Some description',
      items: 'Item 1;Item 2;Item 3',
      name: 'Personal Standard',
    },
    starter: {
      cta: 'Get Started',
      description: 'For personal use',
      items: 'Item 1;Item 2;Item 3',
      name: 'Personal',
    },
  },
};

const subscriptionStatus: Record<
  PaddleSubscriptionStatus,
  { description: string; label: string }
> = {
  active: {
    description: 'Your subscription is active and payments are up to date.',
    label: 'Active',
  },
  canceled: {
    description: 'Your subscription has been canceled and will not renew.',
    label: 'Canceled',
  },
  past_due: {
    description:
      'Your payment is overdue. Please update your billing information.',
    label: 'Past due',
  },
  paused: {
    description: 'Your subscription is currently paused and will resume later.',
    label: 'Paused',
  },
  trialing: {
    description: 'You are currently in a free trial period.',
    label: 'Trial',
  },
};

const translationProduct = {
  billed_annually: 'Billed Annually',
  billed_monthly: 'Billed Monthly',
  billing_details: 'Billing Details',
  next_billing: 'Next billing',
  plans,
  price: 'Price',
  subscription_cancel: 'Cancel Subscription',
  subscription_current: 'Current Plan',
  subscriptionStatus,
};

export default translationProduct;
