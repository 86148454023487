import translationsApiErrors from './api-errors';
import { translationsAuth } from './auth';
import translationsButtons from './buttons';
import translationsClient from './client';
import translationsCommon from './common';
import translationsConfirmation from './confirm';
import translationsExercise from './exercise';
import translationsExerciseInstructions from './exercise-instructions';
import translationForm from './form';
import translationFormField from './form-field';
import translationFormFieldValidationError from './form-field-validation-error';
import translationsPages from './pages';
import translationProduct from './product';
import translationsRoutine from './routine';
import translationsToast from './toasts';
import translationsWorkout from './workout';

const en = {
  auth: translationsAuth,
  button: translationsButtons,
  client: translationsClient,
  common: translationsCommon,
  confirm: translationsConfirmation,
  exercise: translationsExercise,
  exerciseInstruction: translationsExerciseInstructions,
  form: translationForm,
  formField: translationFormField,
  formFieldValidationError: translationFormFieldValidationError,
  page: translationsPages,
  product: translationProduct,
  routine: translationsRoutine,
  toast: { ...translationsToast, ...translationsApiErrors },
  workout: translationsWorkout,
};

export default en;
