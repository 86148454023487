import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import React, { lazy, Suspense, useEffect, useState } from 'react';

import SplashScreen from '../components/layout/SplashScreen';
import envs from '../config/envs';
import { queryClient } from '../config/react-query';
import { useUser } from '../features/user/api/queries/useUser';
import { getCurrentStorageUser } from '../features/user/utils';
import { init18n } from '../i18n/';
import { isDev } from '../utils/environment';

const AppAuthenticated = lazy(
  () => import('./AppAuthenticated/AppAuthenticated'),
);

const AppUnauthenticated = lazy(
  () => import('./AppUnauthenticated/AppUnauthenticated'),
);

const OfflineIndicator = lazy(
  () => import('../components/layout/OfflineIndicator'),
);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: envs.cognito.clientId,
      userPoolId: envs.cognito.userPoolId,
    },
  },
});

const lazyImport = async () => {
  await Promise.all([init18n()]);
};

const App: React.FC = () => {
  const storageUser = getCurrentStorageUser();

  const [loaded, setLoaded] = useState(false);
  const { data: sessionUser = storageUser, isLoading } = useUser();

  const isAppReady =
    loaded === true && (isLoading === false || sessionUser !== null);

  useEffect(() => {
    lazyImport().then(() =>
      setTimeout(() => {
        setLoaded(true);
      }, 100),
    );
  }, []);

  return (
    <>
      <Suspense fallback={null}>
        <OfflineIndicator />
      </Suspense>
      {isAppReady === false ? (
        <SplashScreen />
      ) : (
        <Suspense fallback={null}>
          {storageUser === null ? <AppUnauthenticated /> : <AppAuthenticated />}
        </Suspense>
      )}

      {isDev() === true && (
        <ReactQueryDevtools client={queryClient} initialIsOpen={false} />
      )}
    </>
  );
};

export default App;
