/**
 * @file Contains keys for mutations
 */

export const MUTATION__CLIENT_WORKOUT__DUPLICATE =
  'MUTATION__CLIENT_WORKOUT__DUPLICATE';

export const MUTATION__TEMPLATE_WORKOUT__DUPLICATE =
  'MUTATION__TEMPLATE_WORKOUT__DUPLICATE';

export enum MutationUpdate {
  ClientWorkoutDuplicate = 'MutationUpdate_ClientWorkoutDuplicate',
  ClientWorkoutReset = 'MutationUpdate_ClientWorkoutReset',
  ClientWorkoutSkip = 'MutationUpdate_ClientWorkoutSkip',
  ClientWorkoutUnSkip = 'MutationUpdate_ClientWorkoutUnSkip',
  Exercise = 'MutationUpdate_Exercise',
}

export type MutationUpdateConfirm =
  | MutationUpdate.ClientWorkoutReset
  | MutationUpdate.ClientWorkoutSkip;

export enum MutationDelete {
  Client = 'MutationDelete_Client',
  ClientRoutine = 'MutationDelete_ClientRoutine',
  ClientWorkout = 'MutationDelete_ClientWorkout',
  Exercise = 'MutationDelete_Exercise',
  RoutineCycle = 'MutationDelete_RoutineCycle',
  Subscription = 'MutationDelete_Subscription',
  TemplateRoutine = 'MutationDelete_TemplateRoutine',
  TemplateWorkout = 'MutationDelete_TemplateWorkout',
}
