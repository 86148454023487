import { useQuery } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../config/http';
import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { getCurrentCognitoUser } from '../../../../utils/cognito';
import { CognitoUserTokenSubscription, User } from '../../types';

const fetchUserExtended = async () => {
  const { data } =
    await http.get<
      ApiResponse<{ subscriptions: CognitoUserTokenSubscription[] } & User>
    >('/users');
  return data.data;
};

export const useUserData = () => {
  const { data: user } = useUser();

  return useQuery({
    enabled: user !== null && user !== undefined,
    queryFn: fetchUserExtended,
    queryKey: QueryKey.UserData(),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
};

export const useUser = () => {
  return useQuery({
    queryFn: getCurrentCognitoUser,
    queryKey: QueryKey.User(),
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
};

export const invalidateUser = () =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.User(),
    refetchType: 'all',
    type: 'all',
  });

export const invalidateUserData = () =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.User(),
    refetchType: 'all',
    type: 'all',
  });
