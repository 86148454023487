export enum PageId {
  /**
   * Branding Page - CompanyOwner/PremiumCoach
   */
  Branding = 'Page_Branding',
  /**
   * Clients Page - Coach
   */
  Clients = 'Page_Clients',
  /**
   * Clients Page - Coach
   */
  ClientsDetails = 'Page_ClientDetails',
  /**
   * Clients Page - Coach
   */
  ClientsRoutineDetails = 'Page_ClientRoutineDetails',
  /**
   * Clients Page - Coach
   */
  ClientsWorkoutDetails = 'Page_ClientWorkoutDetails',
  /**
   * Coaches Page - CompanyOwner
   */
  Coaches = 'Page_Coaches',
  /**
   * Company - Company
   */
  Company = 'Page_Company',
  /**
   * Exercises Page - Coach/CompanyOwner/SuperAdmin
   */
  Exercises = 'Page_Exercises',
  /**
   * Facilities - CompanyOwner
   */
  Facilities = 'Page_Facilities',
  /**
   * ForgotPassword
   */
  ForgotPassword = 'Page_ForgotPassword',
  /**
   * Home Page - All
   */
  Home = 'Page_Home',
  /**
   * Library - All
   */
  Library = 'Page_Library',
  /**
   * My Stuff - Coach/PremiumCoach
   */
  MyStuff = 'Page_MyStuff',
  /**
   * When Client Confirms the link, redirects there
   */
  NewPasswordChallenge = 'Page_NewPasswordChallenge',
  /**
   * Onboarding
   */
  Onboarding = 'Page_Onboarding',
  /**
   * Products
   */
  Products = 'Page_Products',
  /**
   * ProductsCheckout
   */
  ProductsCheckout = 'Page_ProductsCheckout',
  /**
   * Reset Password Page
   */
  ResetPassword = 'Page_ResetPassword',
  /**
   * Settings
   */
  Settings = 'Page_Settings',
  /**
   * Settings Profile
   */
  SettingsProfile = 'Page_SettingsProfile',
  /**
   * Settings
   */
  SettingsSubscriptions = 'Page_SettingsSubscriptions',
  /**
   * SignIn
   */
  SignIn = 'Page_SignIn',
  /**
   * SignUp
   */
  SignUp = 'Page_SignUp',
  /**
   * Template Routines - All
   */
  TemplateRoutines = 'Page_TemplateRoutines',
  /**
   * Template Workouts - All
   */
  TemplateWorkouts = 'Page_TemplateWorkouts',
  /**
   * Verify Email Page
   */
  VerifyCode = 'Page_VerifyCode',
  /**
   * Workout Builder
   */
  WorkoutBuilder = 'Page_WorkoutBuilder',
}
