import {
  PaddleBillingInterval,
  PaddleProductType,
  PaddleProductVariant,
  PaddleSubscriptionStatus,
} from '../../types/product.types';

export enum UserType {
  Admin = 'Admin', // Strenco Admin
  Client = 'Client', // Trainees that the coaches train
  Coach = 'Coach', // Individual coach, that can train clients
  CompanyAdmin = 'CompanyAdmin', // Company admin, can create exercises, coaches, assign clients to coaches
  CompanyCoach = 'CompanyCoach', // Coach that belongs to a company
  CompanyOwner = 'CompanyOwner', // The owner of the company
  New = 'New', // Newly signed up user
  Personal = 'Personal', // Can create workouts, exercises for themselves
  SuperAdmin = 'SuperAdmin', // Strenco only
}
export interface CognitoUserTokenSubscription {
  billingInterval: PaddleBillingInterval; // Interval for the billing cycle (e.g., "month", "year")
  billingPeriod: {
    endsAt: string; // End date of the current billing period
    startsAt: string; // Start date of the current billing period
  } | null;
  id: string; // Subscription ID
  items: {
    nextBilledAt: null | string; // Next billing date for the item
    priceId: string; // Price ID for the subscription item
    productId: string; // Product ID for the subscription item
    productType: PaddleProductType; // Type of the product (e.g., "business", "coach", "personal")
    productVariant: PaddleProductVariant; // Variant of the product (e.g., "pro", "standard", "starter")
    status: string; // Status of the subscription item
  }[];
  managementUrls: {
    cancelSubscription: string;
    updatePaymentMethod: string;
  };
  status: PaddleSubscriptionStatus; // Overall status of the subscription (e.g., "active", "inactive")
}

export interface User {
  avatarUrl: null | string;
  customerId: null | string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  subscriptionMaxUsers: number;
  subscriptionPriceId: null | string;
  subscriptionStatus: null | PaddleSubscriptionStatus;
  subscriptionTier: number;
  subscriptionType: null | PaddleProductType;
  subscriptionVariant: null | PaddleProductVariant;
  type: UserType;
}
