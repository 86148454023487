import { Difficulty, Goal } from '../../features/routine/types';
import { ConversionType } from '../../features/routine/types/conversion';

const difficulty: Record<Difficulty, { label: string }> = {
  [Difficulty.AdvancedEasy]: { label: 'Advanced Easy' },
  [Difficulty.AdvancedHard]: { label: 'Advanced Hard' },
  [Difficulty.AdvancedMedium]: { label: 'Advanced Medium' },
  [Difficulty.BeginnerEasy]: { label: 'Beginner Easy' },
  [Difficulty.BeginnerHard]: { label: 'Beginner Hard' },
  [Difficulty.BeginnerMedium]: { label: 'Beginner Medium' },
  [Difficulty.IntermediateEasy]: { label: 'Intermediate Easy' },
  [Difficulty.IntermediateHard]: { label: 'Intermediate Hard' },
  [Difficulty.IntermediateMedium]: { label: 'Intermediate Medium' },
};

const goal: Record<Goal, { label: string }> = {
  [Goal.BodyFatReduction]: { label: 'Body fat Reduction' },
  [Goal.Endurance]: { label: 'Endurance' },
  [Goal.Flexibility]: { label: 'Flexibility' },
  [Goal.GeneralFitness]: { label: 'General Fitness' },
  [Goal.Hypertrophy]: { label: 'Hypertrophy' },
  [Goal.Power]: { label: 'Power' },
  [Goal.Prehab]: { label: 'Prehab' },
  [Goal.Recovery]: { label: 'Recovery' },
  [Goal.Rehab]: { label: 'Rehab' },
  [Goal.Strength]: { label: 'Strength' },
};

const translationsCommon = {
  accomplished: 'Accomplished',
  actions: 'Actions',
  add: 'Add',
  all_time: 'All Time',
  back: 'Back',
  bodyweight: 'Bodyweight',
  changeStatus: 'Change Status',
  checkout: 'Checkout',
  clients_action_needed: 'Clients Action Needed',
  clients_action_no_routines_in_progress: 'No routine in progress',
  close: 'Close',
  comments_for: 'Comments for {{value}}',
  compare: 'Compare',
  completed_at: 'Completed at',
  completedAt: 'Completed at',
  continue: 'Continue',
  conversion: {
    action: 'Create using a template',
    [ConversionType.ClientRoutineToTemplateRoutine]: {
      info: 'A template routine will be created.',
      title: 'Generate template',
    },
    [ConversionType.ClientWorkoutToStandaloneTemplateWorkout]: {
      info: 'A template workout copy will be created.',
      title: 'Generate template',
    },
    [ConversionType.StandaloneTemplateWorkoutToClientWorkout]: {
      info: 'A client routine workout will be generated from this template.',
      title: 'Generate Workout',
    },
    [ConversionType.StandaloneTemplateWorkoutToTemplateRoutineWorkout]: {
      info: 'A template routine workout will be generated from this template.',
      title: 'Generate Workout',
    },
    [ConversionType.TemplateRoutineToClientRoutine]: {
      info: 'A routine will be created for the client.',
      title: 'Generate Routine',
    },
    [ConversionType.TemplateRoutineWorkoutToStandaloneTemplateWorkout]: {
      info: 'A template workout copy will be created.',
      title: 'Generate template',
    },
  },
  dates: {
    annually: 'Annually',
    days: 'days',
    days_ago_one: 'a day ago',
    days_ago_other: '{{count}} days ago',
    days_ago_zero: 'today',
    days_one: '1 Day',
    days_other: '{{count}} days',
    hours: 'hours',
    hours_in_one: 'in 1h',
    hours_in_other: 'in {{count}}h',
    hours_in_zero: 'now',
    month: 'month',
    month_short: 'mo',
    monthly: 'Monthly',
    today: 'today',
  },
  delete: 'Delete',
  delete_value: 'Delete {{value}}',
  difficulty: { ...difficulty, label: 'Difficulty' },
  duplicate: 'Duplicate',
  duplicate_value: 'Duplicate {{value}}',
  duration: 'Duration',
  edit: 'Edit',
  emptyComments: 'No comments added yet!',
  emptyDefault: 'Nothing found',
  ended_at: 'Ended at',
  ends_at: 'Ends at',
  errors: {
    general: {
      title: 'Something went wrong',
    },
  },
  export: 'Export',
  gif: 'Gif',
  goal: { ...goal, label_one: 'Goal', label_other: 'Goals' },
  import: 'Import',
  info: 'Info',
  learn_more: 'Learn More',
  max: 'Max',
  media: 'Media',
  message_offline: `Oops! It looks like there's no internet connection.`,
  move: 'Move',
  name: 'Name',
  of_double: '{{value1}} of {{value2}}',
  position: 'Position {{value}}',
  prepare: 'Prepare',
  provided: 'Provided',
  records_zero: 'No records.',
  replace: 'Replace',
  results: 'Results',
  results_edit: 'Edit results',
  search_templates: 'Search templates',
  shift_to: 'Shift to {{value}}',
  show_less: 'Show Less',
  show_more: 'Show More',
  skipped: 'Skipped',
  started_at: 'Started at',
  status: 'Status',
  summary: 'Summary',
  thumbnail: 'Thumbnail',
  video_personal: 'Personal video',
  weight: 'Weight',
};

export default translationsCommon;
